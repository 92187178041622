export const megaMenuId = 'megamenu';
export const megaMenuInputId = `${megaMenuId}_openclose`;
export const $megaMenuInput = document.querySelector<HTMLInputElement>(`#${megaMenuInputId}`);

/**
 * Gestion des details dans le mega-menu
 * @param shouldExpand
 */
function expandElements(shouldExpand: boolean) {
	const detailsElements = [...document.querySelectorAll<HTMLDetailsElement>(`#${megaMenuId} details`)];
	for (const item of detailsElements) {
		item.open = shouldExpand;
	}
}

export function closeMenu() {
	if (!$megaMenuInput) {
		return;
	}

	$megaMenuInput.checked = false;
	$megaMenuInput.dispatchEvent(new Event('change', { 'bubbles': true }));
}

document.addEventListener('keyup', function (evt: KeyboardEvent) {
	if (!$megaMenuInput?.checked) {
		return;
	}

	if (["Escape", "Esc"].includes(evt.key)) {
		closeMenu();
	}
});

document.addEventListener('change', function (e) {
	const element = (<HTMLElement>e.target).closest<HTMLInputElement>(`#${megaMenuInputId}`);
	if (!element) return;

	if (element.checked) {
		document.documentElement.classList.add('scroll_disabled');
		expandElements(window.screen.width >= 768);
	} else {
		document.documentElement.classList.remove('scroll_disabled');
	}
});
